import {
  ApplicationDocument,
  ApplicationDocumentCreatedVmUpdate,
  ApplicationDocumentUpdatedVmUpdate,
  CreateApplicationDocumentEvent,
  UpdateApplicationDocumentEvent
} from '@/api/models/application.model';
import {axiosInstance, cancelToken, queuedAxiosInstance} from '@/api';
import {ReferenceSign} from '@/api/models/referenceSign.model';
import {ReferenceSignsAppliedVmUpdate} from '@/api/models/applyReferenceSigns.model';
import {blockListPreprocessor} from '@/api/services/editor.api';
import {ApplicationFigureThumbnail} from '@/api/models/applicationFigure.model';
import {BlocksGeneratedVmUpdate} from '@/api/models/editor.model';
import {CancelTokenSource} from 'axios';
import {ApplicationViewSplitMode, ApplicationViewSplitState} from '@/store/models/application.model';

import {USER_PROFILE_PATH} from '@/api/services/userprofile.api';

export const APPLICATION_PATH = '/application';

let referenceSignsCancelSource: CancelTokenSource | null = null;
let figureCancelSource: CancelTokenSource | null = null;

// Run this on Application Documents from the server before storing them
export const applicationDocumentPreprocessor = (applicationDocument: ApplicationDocument): ApplicationDocument => {
  return {
    ...applicationDocument,
    creationDate: new Date(applicationDocument.creationDate),
    date: applicationDocument.date == null ? null : new Date(applicationDocument.date as unknown as string)
  };
};

export const GetApplicationDocument = async (guid: string): Promise<ApplicationDocument> => {
  const res = await queuedAxiosInstance.get(`${APPLICATION_PATH}/${guid}`);
  return applicationDocumentPreprocessor(res?.data as ApplicationDocument);
};

export const CreateApplicationDocument = async (event: CreateApplicationDocumentEvent): Promise<ApplicationDocument> => {
  const res = await queuedAxiosInstance.post(APPLICATION_PATH, event);
  return applicationDocumentPreprocessor((res?.data as ApplicationDocumentCreatedVmUpdate).applicationDocument);
};

export const UpdateApplicationDocument = async (event: UpdateApplicationDocumentEvent): Promise<ApplicationDocumentUpdatedVmUpdate> => {
  const res = await queuedAxiosInstance.put(APPLICATION_PATH, event);
  return {
    applicationDocument: applicationDocumentPreprocessor((res?.data as ApplicationDocumentUpdatedVmUpdate).applicationDocument),
    affectedBlocks: blockListPreprocessor((res?.data as ApplicationDocumentUpdatedVmUpdate).affectedBlocks)
  };
};

export const GetReferenceSignsForApplicationDocument = async (guid: string): Promise<ReferenceSign[]> => {
  referenceSignsCancelSource?.cancel("Cancel old request before starting new GetReferenceSigns.")
  referenceSignsCancelSource = cancelToken.source();
  const res = await axiosInstance.get(`${APPLICATION_PATH}/${guid}/referencesign`,
                                      {cancelToken: referenceSignsCancelSource.token});
  return res?.data as ReferenceSign[];
};

export const GetApplicationFiguresForApplicationDocument = async (guid: string): Promise<ApplicationFigureThumbnail[]> => {
  figureCancelSource?.cancel("Cancel old request before starting new GetApplicationFiguresForApplicationDocument.")
  figureCancelSource = cancelToken.source();
  const res = await axiosInstance.get(`${APPLICATION_PATH}/${guid}/figure`,
                                      {cancelToken: figureCancelSource.token});
  return res?.data as ApplicationFigureThumbnail[];
};

export const ApplyReferenceSignsForApplicationDocument = async (guid: string): Promise<ReferenceSignsAppliedVmUpdate> => {
  const res = await queuedAxiosInstance.post(`${APPLICATION_PATH}/${guid}/applyReferenceSigns`);
  return {
    name: res?.data.name,
    commandStack: res?.data.commandStack,
    referenceSigns: res?.data.referenceSigns,
    changedBlocks: blockListPreprocessor(res?.data.changedBlocks)
  };
}

/**
 * Request generation of all blocks for this application document in the backend
 * @param guid  the GUID of the application document to generate the blocks for
 * @param event the event describing the blocks generated
 */
export const GenerateAllBlocks = async (guid: string): Promise<BlocksGeneratedVmUpdate> => {
  const res = await queuedAxiosInstance.put(`${APPLICATION_PATH}/${guid}/generate`);
  return {
    name: res?.data.name,
    commandStack: res?.data.commandStack,
    addedBlocks: res?.data.addedBlocks,
    updatedBlocks: res?.data.updatedBlocks,
    deletedBlocks: res?.data.deletedBlocks,
    referenceSigns: res?.data.referenceSigns,
    isStructurallySound: res?.data.isStructurallySound
  } as BlocksGeneratedVmUpdate;
}

let changeApplicationSplitModeCancelToken: CancelTokenSource | null = null;

/**
 * Requests the current application view split state for the user with the given user id.
 * @param userId
 * @constructor
 */
export const GetApplicationViewSplitState = async (userId: string): Promise<ApplicationViewSplitState> => {
  const res = await axiosInstance.get(`${USER_PROFILE_PATH}/${userId}/applicationViewSplitState`);
  return res.data as ApplicationViewSplitState;
}

export const PutToggleApplicationViewSplitState = async (userId: string, mode: ApplicationViewSplitMode ) => {
  changeApplicationSplitModeCancelToken?.cancel("Cancel old request before starting new PutToggleApplicationViewSplitState.")
  changeApplicationSplitModeCancelToken = cancelToken.source();
  const res = await axiosInstance.put(
      `${USER_PROFILE_PATH}/${userId}/applicationViewSplitState/toggle?applicationViewSplitMode=${mode}`,
          undefined,
    {  cancelToken: changeApplicationSplitModeCancelToken.token}
  );
  return res.data as ApplicationViewSplitState;
}

let updateDocumentAndFiguresViewSplitStateCancelToken: CancelTokenSource | null = null;

export interface UpdateDocumentAndFiguresSplitStateParam {
  userId: string;
  state: ApplicationViewSplitState;
}

export const PutUpdateDocumentAndFiguresViewSplitState = async (param: UpdateDocumentAndFiguresSplitStateParam) => {
  updateDocumentAndFiguresViewSplitStateCancelToken?.cancel("Cancel old request before starting new PutUpdateDocumentAndFiguresViewSplitState.")
  updateDocumentAndFiguresViewSplitStateCancelToken = cancelToken.source();
  const res = await axiosInstance.put(
      `${USER_PROFILE_PATH}/${param.userId}/applicationViewSplitState/documentAndFigures`,
          param.state,
    {  cancelToken: updateDocumentAndFiguresViewSplitStateCancelToken.token}
  );
  return res.data as ApplicationViewSplitState;
}